import React from "react";
import './App.css';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Error from "./Components/404";
import  {Switch, Route} from "react-router-dom";
import Home from "./Pages/Home";

const App = () => {
  return (
    <>
      <Header/>
      <Switch>
        <Route exact path="/">
          <Home/>
        </Route>
        <Route>
          <Error />
        </Route>
      </Switch>
      <Footer/>
    </>
  )
}

export default App;