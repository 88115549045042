import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="error-page">
      <div className="container error-page-info">
        <h2>404</h2>
        <h3>Page not found</h3>
        <p>This Page you are looking for doesn't exist or an other error occurred.</p>
        <p>Go back, or head to <Link className="navigation-link-to-website">aryanbhalla.com</Link> to choose a new direction.</p>
        <Link to="/" className="button-prim">GO HOME&nbsp;&nbsp;<i class="bi bi-arrow-right"></i></Link>
      </div>
    </div>
  )
}

export default Error;