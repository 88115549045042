import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>    
      <div className="top-bar">
        <div className="container top-info-bar">
          <p>Welcome to Aryan's Portfolio</p>
          <ul>
            <li><i className="bi bi-telephone-fill"></i>+1 (431)-373-3690</li>
            <li><i className="bi bi-envelope"></i>Aryanbhalla66@gmail.com</li>
            <li><i className="bi bi-linkedin"></i>Aryanbhalla</li>
          </ul>
        </div>
      </div>
      <div className="navigation-bar">
        <div className="container navigation-info-bar">
          <Link to="/" className="logo-header"><h2>Aryan Bhalla.</h2></Link>
          <nav>
            <ul>
              <li><Link to="/" className="link link--metis"><span>01.</span>Home</Link></li>
              <li><Link to="/" className="link link--metis"><span>02.</span>About</Link></li>
              <li><Link to="/" className="link link--metis"><span>03.</span>Projects</Link></li>
              <li><Link to="/" className="link link--metis"><span>04.</span>Experience</Link></li>
              <li><Link to="/" className="link link--metis"><span>05.</span>Contact</Link></li>
            </ul>
          </nav>
          <Link to="/" className="button-prim-ghost">Resume / CV</Link>
        </div>
      </div>
    </>
  )
}

export default Header;