import { Link } from "react-router-dom";
import "./Style/Home.css";

const Home = () => {
  return (
    <div className="home-wrapper">
      <div className="container home-wrapper-info">
        <div className="col-left-slide col-slide">
          <h4>Hello I'm</h4>
          <h1>Aryan Bhalla</h1>
          <h3>Web Developer</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
          <ul>
            <li><Link to="/"><i className="bi bi-github"></i></Link></li>
            <li><Link to="/"><i className="bi bi-twitter"></i></Link></li>
            <li><Link to="/"><i className="bi bi-linkedin"></i></Link></li>
          </ul>
        </div>
        <div className="col-right-slide col-slide">
        </div>
      </div>
      <div className="col-cards">
        <div className="container col-cards-info">
          <ul>
            <li>asdas</li>
            <li>ads</li>
            <li>sds</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Home;