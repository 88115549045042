import { Link } from "react-router-dom";

const Footer = () => {
  return(
    <>
      <footer>
        <div className="container footer-info">
          <div className="developer-info col-fot">
            <h2>Aryan Bhalla</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
            <ul>
              <li><Link to="/"><i className="bi bi-github"></i></Link></li>
              <li><Link to="/"><i className="bi bi-twitter"></i></Link></li>
              <li><Link to="/"><i className="bi bi-linkedin"></i></Link></li>
            </ul>
          </div>
          <div className="quick-link col-fot footer-list">
            <h2>Quick Links</h2>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/">Projects</Link></li>
              <li><Link to="/">About</Link></li>
              <li><Link to="/">Contact</Link></li>
            </ul>
          </div>
          <div className="col-fot contact-list">
            <h2>Contacts</h2>
            <ul>
              <li>
                  <i className="bi bi-telephone-fill"></i>
                  <div>
                    <span>Phone</span>
                    <p>+1 (431)-373-3690</p>
                  </div>
              </li>
              <li>
                <i className="bi bi-envelope-fill"></i>
                <div>
                  <span>Email</span>
                  <p>aryanbhalla66@gmail.com</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="copyright-col">
        <div className="container copyright-info">
          <p>&copy; Copyright 2022 Aryan Bhalla. All Rights Reserved</p>
        </div>
      </div>
    </>
  )
}

export default Footer;